export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		PRACTICING: 3,
		PATIENT: 4,
		SELLER: 5
	},
	USER: {
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		}
	},
	MODULES: {
		USERS: 1,
		SERVICES: 2,
		CLUB: 3,
		PLANS: 4,
		TICKETS: 5,
		FINANCING: 6,
		REPORTS: 7,
		HEALTH_CENTERS: 8,
		SERVICE_MANAGEMENT: 9,
		PAYMENT_MANAGEMENT: 10,
		CONVERSION: 11,
		CARDS: 12,
		MICROCHIP: 13,
		PRODUCTS: 14,
		ORDERS: 15,
		CODES: 16,
		HOME: 17, // No va en el seeder, solo para admin
	},
	PLAN_TYPES: {
	    HEALTH: 1,
	    CLUB: 2
	},
	MODALITIES: {
	    INVASIVE: 1,
	    NO_INVASIVE: 2
	},
	PURCHASED_SERVICES_STATUS: {
	    PENDING: 0,
	    ACTIVE: 1,
	    FINISH: 2
	},
	PRACTICING_PAYMENT_STATUS: {
		PENDING: 0,
		FINISH: 1
	},
	PAYMENT_METHODS: {
	    ZELLE: 1
	},
	FINANCING_STATUS: {
	    PENDING: 0,
	    APPROVED: 1,
	    FINISH: 2,
	    REJECTED: 3
	},
	PLAN_USER_STATUS: {
		ACTIVE: 1,
		INACTIVE: 0
	},
	FINANCING_FILES: {
		DOCUMENT: 1,
		SELFIE: 2
	},
	FINANCING_DATE_STATUS: {
	    PENDING: 0,
	    PAID: 1
	},
	PLAN_SERVICE_UNLIMITED: {
	    YES: 1,
	    NO: 0
	},
	SHOW_SPECIALITIES_LIST: {
		YES: 1,
		NO: 0
	},
	GIFT_CARD_STATUS: {
	    PENDING: 0,
	    SELL: 1,
	    USED: 3,
	    CANCELLED: 4
	},
	GIFT_CARD_ORIGIN: {
		GENERATED: 1,
		WEB: 2
	},
	PAYMENT_REFERENCES: {
	    PENDING: 0,
	    APPROVED: 1,
	    REJECTED: 2
	},
	PRODUCT_TYPES: {
		NORMAL: 1,
		SIZE_COLORS: 2
	},
	ORDERS_STATUS: {
	    PENDING: 0,
	    APPROVED: 1,
	    FINISH: 2,
	    REJECTED: 3
	},
	PAY_WITH_TICKETS: {
	    YES: 1,
	    NO: 0
	},
	PLAN_STATUS: {
		ACTIVE: 1,
		INACTIVE: 0
	},
	REMEMBER_TYPES: {
	    VACCINATION: 1,
	    DEWORMING: 2,
	    GROOMING: 3
	},
	FREE_PLAN: {
	    YES: 1,
	    NO: 0
	}
}